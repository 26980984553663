import { extendTheme } from "@chakra-ui/react";

export const customTheme = extendTheme({
  config: {
    cssVarPrefix: "ww",
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  colors: {
    brand: {
      100: "#FBFBFD",
      200: "#A6D73F",
      300: "#767676",
      400: "#121609",
      500: "#788E48",
      600: "#6E6E73",
      700: "#241906",
      800: "#CBCBCB",
      900: "#FF6868",
    },
  },
  fonts: {
    body: "Satoshi",
    heading: "Satoshi",
  },
  styles: {
    global: {
      body: {
        bg: "brand.100",
        color: "brand.400",
      },
    },
  },
});
